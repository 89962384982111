import { Controller } from "@hotwired/stimulus";

const PUSHED = "is-pushed";

export default class extends Controller {
  static targets = ["main", "sidebar", "topbar"];

  toggle(event) {
    const body = this.element;

    event.preventDefault();

    [body, this.mainTarget, this.sidebarTarget, this.topbarTarget].forEach(
      (e) => {
        e.classList.toggle(PUSHED);
      }
    );
  }

  hide(event) {
    const body = this.element;

    if (!this.mainTarget.classList.contains(PUSHED)) return;

    [body, this.mainTarget, this.sidebarTarget, this.topbarTarget].forEach(
      (e) => {
        e.classList.remove(PUSHED);
      }
    );
  }
}
