import { Controller } from "@hotwired/stimulus";

const selectedClass = "categories-show-item__selected";

export default class extends Controller {
  static targets = ["show"];

  connect() {
    this.selectCurrentShowInSidebar();
    this.scrollToCurrentShow();
  }

  selectCurrentShowInSidebar() {
    if (!this.showTargets) return;

    this.showTargets.forEach((item) => item.classList.remove(selectedClass));

    let selectedFilm = this.showTargets.find(
      (item) => item.dataset.url === window.location.pathname
    );
    selectedFilm?.classList.add(selectedClass);
  }

  scrollToCurrentShow() {
    if (!this.showTargets) return;

    let selectedFilm = this.showTargets.find(
      (item) => item.dataset.url === window.location.pathname
    );

    selectedFilm?.scrollIntoView({ block: "center" });
  }
}
