import { Controller } from "@hotwired/stimulus";

const key = "welcome-banner-2022";

export default class extends Controller {
  initialize() {
    if (!window.localStorage.getItem(key)) {
      this.element.removeAttribute("hidden");
    }
  }
  dismiss() {
    window.localStorage.setItem(key, "true");
    this.element.setAttribute("hidden", "");
  }
}
